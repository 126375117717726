import React, { useState, useEffect } from 'react';
import './WalletChecker.css'; // Ensure this path is correct

const WalletChecker = () => {
  const [wallet, setWallet] = useState('');
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setMessage('');
      setIsError(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, [message]);

const checkWallet = async () => {
  try {
    const whitelistedResponse = await fetch('whitelisted.json');
    const guaranteedResponse = await fetch('guaranteed.json');

    if (!whitelistedResponse.ok || !guaranteedResponse.ok) {
      throw new Error('Failed to fetch');
    }

    const whitelistedData = await whitelistedResponse.json();
    const guaranteedData = await guaranteedResponse.json();

    // Convertendo para minúsculas para comparação insensível a maiúsculas/minúsculas
    const walletLowerCase = wallet.toLowerCase();
    const isGuaranteed = guaranteedData.guaranteed.map(w => w.toLowerCase()).includes(walletLowerCase);
    const isWhitelisted = whitelistedData.whitelist.map(w => w.toLowerCase()).includes(walletLowerCase);

    if (isGuaranteed) {
      setMessage('Guaranteed WL');
    } else if (isWhitelisted) {
      setMessage('Whitelisted FCFs');
    } else {
      setMessage('Not Whitelisted or Guaranteed');
    }

    setIsError(!isWhitelisted && !isGuaranteed);
  } catch (error) {
    setMessage('Error fetching the lists');
    setIsError(true);
    console.error('Error:', error);
  } finally {
    setWallet('');
  }
};

  const handleSubmit = (event) => {
    event.preventDefault();
    if (wallet.trim()) {
      checkWallet();
    }
  };

  return (
    <form onSubmit={handleSubmit} className="wallet-checker-form">
      <input
        type="text"
        value={wallet}
        onChange={(e) => setWallet(e.target.value)}
        placeholder="Enter wallet address"
        className="wallet-checker-input"
      />
      <button type="submit" className="wallet-checker-button" disabled={!wallet.trim()}>
        Check
      </button>
      <div className={`wallet-checker-message ${isError ? 'error' : ''}`}>
        {message}
      </div>
    </form>
  );
};

export default WalletChecker;
